const EmptyMyOffers = () => import('./EmptyMyOffers.vue');
const EmptyOffers = () => import('./EmptyOffers.vue');
const CardListHeader = () => import('./CardListHeader.vue');
const MyOfferHeader = () => import('./MyOfferHeader.vue');
const SearchLocations = () => import('./SearchLocations.vue');
const OffersTab = () => import('./OffersTab.vue');
const OfferCard = () => import('./OfferCard.vue');
const Header = () => import('./Header.vue');
const MainContainer = () => import('./MainContainer.vue');
const FoodBankOfferCard = () => import('./FoodBankOfferCard.vue');
const FoodBankTabs = () => import('./FoodBankTabs.vue');
const ExploreOfferCards = () => import('./ExploreOfferCards.vue');
const ClickPercentageOfferCard = () => import('./ClickPercentageOfferCard.vue');

export {
  Header,
  MainContainer,
  EmptyMyOffers,
  EmptyOffers,
  MyOfferHeader,
  SearchLocations,
  OffersTab,
  OfferCard,
  CardListHeader,
  FoodBankOfferCard,
  FoodBankTabs,
  ExploreOfferCards,
  ClickPercentageOfferCard
};
