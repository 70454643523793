
import { Component, Vue } from 'vue-property-decorator';

import { Suggestion, ChartContainer } from '@/components/consumer';
import {
  RoundUpBanner,
  RoundUpBoosters,
  RoundUpDashboard,
  RoundUpPaymentHistory
} from '@/components/round-up';
import { MainContainer, Header } from '@/components/offers';
import { FeatureModule, RoundUpModule } from '@/store/modules';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { RoundUpService } from '@/services';

@Component({
  components: {
    Suggestion,
    MainContainer,
    Header,
    ChartContainer,
    RoundUpBanner,
    RoundUpBoosters,
    RoundUpDashboard,
    RoundUpPaymentHistory
  },
  // NOTE: When user switches between "Round UP" and "Impact Dashboard" tabs it doesn't unmount
  // this component, so had to place a watcher on route, to detect when it changes
  watch: {
    $route(to) {
      setTimeout(() => {
        const scrollToRoundUp = to.query.scrollToRoundUp;
        if (scrollToRoundUp) {
          this.$el.querySelector('.roundUpSection')?.scrollIntoView(true);
          return;
        }
        window.scrollTo(0, 0);
      }, 250);
    }
  }
})
export default class Dashboard extends Vue {
  currentTenant = tenants[ActiveTenant];
  titleTextColor = tenants[ActiveTenant].text.impactDashboardTitleColor;
  isOffersEnabled = FeatureModule.getIsOffersEnabled;
  isRoundUpEnabled = FeatureModule.getIsRoundUpEnabled;
  roundUpRule = RoundUpModule.getRoundUp;
  scrollToRoundUp = this.$route.query.scrollToRoundUp;

  async fetchRoundUpRuleForUser(): Promise<void> {
    await RoundUpService.getRule();
    this.roundUpRule = RoundUpModule.getRoundUp;
  }

  async created() {
    this.fetchRoundUpRuleForUser();
  }

  async mounted() {
    if (!this.scrollToRoundUp) return;
    // NOTE: Had to place timeout here because without it roundUpSection component doesn't
    // exist when I try to access it here
    setTimeout(() => {
      this.$el.querySelector('.roundUpSection')?.scrollIntoView(true);
    }, 250);
  }
}
